import React, { useState, useEffect } from "react";
import { Button, Drawer, Form, Select, Input, Card, Space } from "antd";
import { useSelector } from "react-redux";
import { dispatch } from "store/index";
import { FetchAddActivity } from "store/action/index";

const AddActivityDrawerRight = ({ children, data }) => {
    const [open, setOpen] = useState(false);
    const [size, setSize] = useState();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const selector = useSelector((state) => state);
    const allActivitiesData = selector?.AllActivities?.allActivities?.data ?? [];

    const showLargeDrawer = () => {
        setSize("large");
        setOpen(true);
    };
    console.log(data, 'all activity datas ')
    const onClose = () => {
        setOpen(false);
    };

    const onFinish = (values) => {
        const allValues = { ...form.getFieldValue(), campaign_id: data?.id };
        dispatch(FetchAddActivity({ setLoading: setIsLoading, fieldData: allValues, onClose }));
    };


    return (
        <>
            <div style={{ width: "100%" }} onClick={showLargeDrawer}>
                {children}
            </div>
            <Drawer
                title="Add Activity"
                zIndex={10000}
                width={600}
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
            >
                <div className="container-fluid mt-1">
                    <div className="row">
                        <Form
                            form={form}
                            name="add-activity"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ maxWidth: "100%" }}
                            onFinish={onFinish}
                            autoComplete="off"
                            className="mt-3"
                        >
                            <Card size="small" title="Add New Activity" style={{ marginBottom: "20px" }}>
                                <Form.Item
                                    label="Activity Name"
                                    name="name"
                                    rules={[
                                        { required: true, message: "Please enter the activity name!" },
                                    ]}
                                >
                                    <Input placeholder="Enter Activity Name" />
                                </Form.Item>
                                <Form.Item
                                    label="Activity Type"
                                    name={["activity_id"]}
                                    rules={[
                                        { required: true, message: "Please select an activity!" },
                                    ]}
                                >
                                    <Select placeholder="Select Activity">
                                        {allActivitiesData.map((option) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>


                            </Card>

                            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>
                                        Submit
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default AddActivityDrawerRight;
