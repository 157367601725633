// type
import { ShopRemove } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import {
    can_add_users, can_edit_users, can_view_users,
} from "helper/permissionsCheck";
import { ImInsertTemplate } from "react-icons/im";
// icons
const icons = {
    navigation: MdHome,
    templates: ImInsertTemplate
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

let decryptedData = null;
if (permissionUser) {
    try {
        // Code that may throw an exception
        decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
        // ...
    } catch (error) {
        console.log(error, "er")
    }
}

const permissions = decryptedData ? JSON.parse(decryptedData)?.permissions : null;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
    return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
}
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const templates = (can_add_users || can_edit_users || can_view_users) ? {
    id: 'ddall-templates',
    title: 'Templates',
    icon: icons.navigation,
    type: 'group',
    children: [

        // allowedPermissions.includes('get-company') ? {


        (can_add_users || can_edit_users || can_view_users) ? {
            id: 'email',
            title: 'Email Templates',
            type: 'item',
            url: '/templates/email-templates',
            icon: icons.templates,
            breadcrumbs: true
        } : null,


        can_add_users ? {
            id: 'whatsapp',
            title: 'WhatsApp Templates',
            type: 'item',
            url: '/templates/whatsapp-templates',
            icon: icons.templates,
            breadcrumbs: true
        } : null,
        true ? {
            id: 'sms',
            title: 'Sms Templates',
            type: 'item',
            url: '/templates/sms-templates',
            icon: icons.templates,
            breadcrumbs: true
        } : null,
    ].filter(Boolean)
} : null;

export default templates;
