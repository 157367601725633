import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import {
  can_add_users,
  can_edit_users,
  can_view_users,
} from "helper/permissionsCheck";

// Define icons
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

// Define Leads menu based on permissions
const tagsMenu =
  can_add_users || can_edit_users || can_view_users
    ? {
        id: "tags-group",
        title: "Tags",
        icon: icons.navigation,
        type: "group",
        children: [
          (can_add_users || can_edit_users || can_view_users) && {
            id: "all-tags",
            title: "All Tags",
            type: "item",
            url: "/tags/all-tags",
            icon: icons.allLeads,
            breadcrumbs: true,
          },
        ].filter(Boolean),
      }
    : null;

export default tagsMenu;
