import React, { useEffect } from "react"; // Include useEffect in the import
import { Card, Avatar, Progress, Typography, Row, Col, Tooltip } from "antd";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserOutlined, ReloadOutlined, StopOutlined } from "@ant-design/icons";

const CampaignCard = ({ campaign }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (campaign?.id == 3) {
      navigate("/activities/campaign-whatsapp-conversations");
    } else {
      navigate("/activities/call-leads");
    }
  };

  return (
    <Card title={campaign?.name} bordered={false} style={{ minWidth: "450px" }}>
      <Row align="middle" justify="space-center" className="gap-5 ">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>Members</Typography.Text>
        </Col>
        <Col>
          <Avatar.Group>
            {campaign?.members?.map((v) => (
              <Tooltip title={v?.name} > <Avatar icon={<UserOutlined />} />  </Tooltip>
            ))}
          </Avatar.Group>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>Call</Typography.Text>
        </Col>
        <Col>
          <Progress percent={9} showInfo={true} />
          <Typography.Text>Remaining Leads: 4/44</Typography.Text>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>Email</Typography.Text>
        </Col>
        <Col>
          <Progress percent={9} showInfo={true} />
          <Typography.Text>Remaining Leads: 8/44</Typography.Text>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>WhatsApp</Typography.Text>
        </Col>
        <Col>
          <Progress percent={9} showInfo={true} />
          <Typography.Text>Remaining Leads: 8/44</Typography.Text>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>SMS</Typography.Text>
        </Col>
        <Col>
          <Progress percent={9} showInfo={true} />
          <Typography.Text>Remaining Leads: 8/44</Typography.Text>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>Last Actioner:</Typography.Text>
        </Col>
        <Col>
          <Typography.Text> Admin</Typography.Text>
        </Col>
      </Row>
      <Row className="gap-5 mt-3">
        <Col style={{ minWidth: "150px" }}>
          <Typography.Text strong>Started On:</Typography.Text>
        </Col>

        <Col>
          <Typography.Text> 10-09-2024 06:32 pm</Typography.Text>
        </Col>
      </Row>

      <Row className="gap-5 mt-4 " style={{ marginTop: "16px" }}>
        <Button
          variant="outlined"
          style={{ width: "100%", marginTop: "20px" }}
          onClick={handleNavigate}
        >
          Start
        </Button>
      </Row>
    </Card>
  );
};

export default CampaignCard;
