import React, { useState } from "react";
import {
  Button,
  Drawer,
  Space,
  Form,
  Select,
  DatePicker,
  Row,
  Col,
} from "antd";
import CsvUploaderLeads from "components/inputs/CsvUploaderLeads";
import { useSelector } from "react-redux";
import PaginatedSelect from "components/selects/SelectPagination";
import { dispatch } from "store/index";
import { FetchAllSelectLeads, AddLeadActivity } from "store/action/index";
import { Typography } from "@mui/material";

const AddLeadsDrawer = ({ children, datacampaign }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const selector = useSelector((state) => state);
  const allLeadsData = selector?.AllLeadsSelectReducer?.allLeads?.data ?? [];
  const allMembersData = selector?.GetAllUsers?.getAllUsers?.users ?? [];
  const allActivitiesData = selector?.AllActivities?.allActivities?.data ?? [];

  const showLargeDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    const allValues = {
      ...form.getFieldValue(),
      csv_leads: dataSource,
      //   new_leads_counts: dataSource?.length,
      activity_id: datacampaign?.activity_id_specific,
    };
    allValues.start_date = new Date(allValues?.start_date?.$d)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");
    dispatch(AddLeadActivity({ fieldData: allValues }));

    console.log("CHekCSVVV", datacampaign);
  };

  const callAllLeadsApi = (setLoading, params) => {
    dispatch(FetchAllSelectLeads({ setLoading: setLoading, params: params }));
  };

  return (
    <>
      <div onClick={showLargeDrawer}>{children}</div>
      <Drawer
        title="Add Leads"
        width={1200}
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="container-fluid mt-1">
          <Form
            form={form}
            name="addLeads"
            onFinish={onFinish}
            autoComplete="off"
            className="mt-3"
          >
            <Typography className="text-center mb-3" variant="h2">
              ADD LEADS
            </Typography>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Activity"
                  name="activity"
                  rules={[{ required: true, message: "Please select a value" }]}
                >
                  <Select placeholder="Select Activity" mode="multiple">
                    {allActivitiesData.map((v) => (
                      <Select.Option key={v.id} value={v.id}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Members"
                  name="members"
                  rules={[{ required: true, message: "Please select a value" }]}
                >
                  <Select placeholder="Select Members" mode="multiple">
                    {allMembersData.map((v) => (
                      <Select.Option key={v.id} value={v.id}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Leads"
                  name="leads"
                  rules={[{ required: true, message: "Please select a value" }]}
                >
                  <PaginatedSelect
                    optKey={"name"}
                    optValue={"id"}
                    opt={allLeadsData}
                    callApi={callAllLeadsApi}
                    allData={selector?.AllLeadsSelectReducer?.allLeads ?? {}}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select a start date!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    className="flex-1"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <CsvUploaderLeads
              setDataSource={setDataSource}
              dataSource={dataSource}
            />

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default AddLeadsDrawer;
