import React, { useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import MainCard from "components/MainCard";

const SmsSetting = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();

    const isEditing = location.state ? true : false;
    const initialData = location.state ? location.state : null;

    useEffect(() => {
        if (isEditing && initialData) {
            form.setFieldsValue({
                'sms-provider': initialData.smsProvider,
                'sms-api-key': initialData.apiKey,
                'sms-api-secret': initialData.apiSecret,
                'sms-sender-id': initialData.senderId,
            });
        }
    }, [form, initialData, isEditing]);

    const onFinish = (values) => {
        if (isEditing) {
            message.success('SMS setting updated successfully!');
        } else {
            message.success('SMS setting created successfully!');
        }
        navigate('/settings/all-sms-settings');
    };

    return (
        <MainCard>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="sms-provider"
                    label="SMS Provider"
                    tooltip="Enter your SMS provider name"
                    rules={[{ required: true, message: 'Please enter the SMS provider' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="sms-api-key"
                    label="API Key"
                    tooltip="Enter the SMS provider API key"
                    rules={[{ required: true, message: 'Please enter the API Key' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="sms-api-secret"
                    label="API Secret"
                    tooltip="Enter the API Secret for the SMS provider"
                    rules={[{ required: true, message: 'Please enter the API Secret' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {isEditing ? 'Update' : 'Submit'}
                    </Button>
                </Form.Item>
            </Form>
        </MainCard>
    );
};

export default SmsSetting; 
