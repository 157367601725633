import React from "react";
import GetColumnSearchProps from "helper/searchHelper";
import { Button } from "@mui/material";
import { format } from "date-fns";
import { CloseCircleOutlined, CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip, Avatar } from "antd";
import {
  editButton,
  deactivateButton,
  renderButton,
} from "../actionButtons/actionsButtons";
import { FaRegEdit } from "react-icons/fa";
import dayjs from "dayjs";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { MdDelete } from "react-icons/md";
import { AES, enc } from "crypto-js";
import * as url from "../../store/constant/Endpoints";

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray = decryptedData && JSON.parse(decryptedData);

export const compaignsColumns = ({ params, setparams, handleNavigate }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      ...GetColumnSearchProps("name", params, setparams),
      key: "name",
    },

    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (text, record) => {
        return (
          <div className="d-flex gap-2 flex-wrap">
            {text?.map((v) => (
              <div style={{ cursor: 'pointer' }} > <Tooltip title={v?.name} > <Avatar icon={<UserOutlined />} />  </Tooltip></div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "activities",
      render: (text, record) => {
        return <Button variant='contained' onClick={() => handleNavigate(record)} >View Activities</Button>
      }
    },
  ];
};





export const callsLogColumns = ({ params, setparams, handleNavigate }) => {
  return [
    {
      title: "Duration",
      dataIndex: "Duration",
      ...GetColumnSearchProps("name", params, setparams),
      key: "Duration",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Lead Name",
      dataIndex: "LeadName",
      key: "Lead Name",
    },
    {
      title: "Agent Name",
      dataIndex: "AgentName",
      key: "AgentName",
    },
    {
      title: "Lead Number",
      dataIndex: "LeadNumber",
      key: "LeadNumber",
    },
    {
      title: "Download Audio",
      dataIndex: "DownloadAudio",
      key: "DownloadAudio",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "activities",
    //   render: (text, record) => {
    //     return <Button variant='contained' onClick={() => handleNavigate(record)} >View Activities</Button>
    //   }
    // },
  ];
};




export const smsLogColumns = ({ params, setparams, handleNavigate }) => {
  return [
    {
      title: "Lead Name",
      dataIndex: "LeadName",
      ...GetColumnSearchProps("name", params, setparams),
      key: "Lead Name",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      ...GetColumnSearchProps("name", params, setparams),
      key: "Phone",
    },
    {
      title: "Sent Time",
      dataIndex: "SentTime",
      ...GetColumnSearchProps("name", params, setparams),
      key: "SentTime",
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span>
          {status === 'Sent' ? (
            <Tooltip title="Sent">
              <CheckCircleOutlined
                style={{ color: "blue", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Failed' ? (
            <Tooltip title="Failed">
              <CloseCircleOutlined
                style={{ color: "red", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Read' ? (
            <Tooltip title="Read">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : null}
        </span>
      )
    }

    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "activities",
    //   render: (text, record) => {
    //     return <Button variant='contained' onClick={() => handleNavigate(record)} >View Activities</Button>
    //   }
    // },
  ];
};

export const emailLogColumns = ({ params, setparams, handleNavigate }) => {
  return [
    {
      title: "Lead Name",
      dataIndex: "LeadName",
      ...GetColumnSearchProps("name", params, setparams),
      key: "LeadName",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Sent Time",
      dataIndex: "SentTime",
      key: "SentTime ",
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span>
          {status === 'Sent' ? (
            <Tooltip title="Sent">
              <CheckCircleOutlined
                style={{ color: "blue", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Failed' ? (
            <Tooltip title="Failed">
              <CloseCircleOutlined
                style={{ color: "red", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Read' ? (
            <Tooltip title="Read">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : null}
        </span>
      )
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "activities",
    //   render: (text, record) => {
    //     return <Button variant='contained' onClick={() => handleNavigate(record)} >View Activities</Button>
    //   }
    // },
  ];
};

export const whatsappLogColumns = ({ params, setparams, handleNavigate }) => {
  return [
    {
      title: "Lead Name",
      dataIndex: "LeadName",
      ...GetColumnSearchProps("name", params, setparams),
      key: "start_date",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      ...GetColumnSearchProps("name", params, setparams),
      key: "Phone",
    },
    {
      title: "Sent Time",
      dataIndex: "SentTime",
      ...GetColumnSearchProps("name", params, setparams),
      key: "AgentName",
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <span>
          {status === 'Sent' ? (
            <Tooltip title="Sent">
              <CheckCircleOutlined
                style={{ color: "blue", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Failed' ? (
            <Tooltip title="Failed">
              <CloseCircleOutlined
                style={{ color: "red", fontSize: "24px" }}
              />
            </Tooltip>
          ) : status === 'Read' ? (
            <Tooltip title="Read">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : null}
        </span>
      )
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "activities",
    //   render: (text, record) => {
    //     return <Button variant='contained' onClick={() => handleNavigate(record)} >View Activities</Button>
    //   }
    // },
  ];
};

export const allLeadsColumns = ({ params, setParams }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      ...GetColumnSearchProps("name", params, setParams),
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "start_date",
      render: (text, record) => {
        return (
          <div>
            {text.map((v) => (
              <span
                style={{
                  color: v.color ?? "black",
                  padding: "3px 15px ",
                  backgroundColor: v.color
                    ? `${v.color}50`
                    : "rgba(0, 0, 0, 0.5)",
                  borderRadius: 5,
                  margin: 2,
                }}
              >
                {" "}
                {v.name}{" "}
              </span>
            ))}
          </div>
        );
      },
    },
  ];
};
export const allTagsColumns = ({ params, setParams }) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      ...GetColumnSearchProps("name", params, setParams),
      key: "name",
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (color) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: color,
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              marginRight: "8px",
              border: "1px solid #d9d9d9",
            }}
          />
          <span>{color}</span>
        </div>
      ),
    },
  ];
};

export const allActivitiesColumns = () => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];
};
export const allTeamsColumns = () => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Members",
      dataIndex: "users",
      key: "users",
      render: (text, record) => {
        return (
          <div className="d-flex gap-2 flex-wrap">
            {text?.map((v) => (
              <div style={{ cursor: 'pointer' }} > <Tooltip title={v?.name} > <Avatar icon={<UserOutlined />} />  </Tooltip></div>
            ))}
          </div>
        );
      },
    },
  ];
};

export const LeadsDetails = () => {
  return [
    {
      title: "Campaign",
      dataIndex: "campaign",
      key: "campaign",
      render: (text, record) => {
        return <>{text?.name}</>;
      },
    },
    {
      title: "Duration",
      dataIndex: "activity_action",
      key: "activity_action",
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      render: (text, record) => {
        return <>{text?.name}</>;
      },
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Performed On",
      dataIndex: "performed_on",
      key: "performed_on",
      render: (text, record) => {
        return <>{dayjs(record.performed_on).format("D MMMM YYYY")}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
};


export const AllWhatgsappTemplate = () => {
  return [
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
    },
    {
      title: "Template Content",
      dataIndex: "template_content",
      key: "template_content",
    },
  ];
};


export const AllSmsTemplate = () => {
  return [
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
    },
    {
      title: "Template Content",
      dataIndex: "template_content",
      key: "template_content",
    },
  ];
};


export const AllEmailSettingColumns = () => {
  return [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Host Name',
      dataIndex: 'hostname',
      key: 'hostname',
    },

    {
      title: 'Encryption Method',
      dataIndex: 'encryption_method',
      key: 'encryption_method',
    },
    {
      title: 'Port',
      dataIndex: 'port',
      key: 'port',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (
        <span>

          {active == 1 ? (
            <Tooltip title="Active">
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "24px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Inactive">
              <CloseCircleOutlined
                style={{ color: "red", fontSize: "24px" }}
              />

            </Tooltip>
          )}
        </span>
      )
    }
  ];
};


export const AllWhatsappSettingCOlumns = () => {
  return [
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
    },
    {
      title: "Business Email",
      dataIndex: "business_email",
      key: "business_email",
    },
    {
      title: "Business Description",
      dataIndex: "business_description",
      key: "business_description",
    },
    {
      title: "Busniess Industry",
      dataIndex: "business_industry",
      key: "business_industry",
    },
    {
      title: "Business Address",
      dataIndex: "business_address",
      key: "business_address",
    },
    {
      title: "Connected Number",
      dataIndex: "connected_number",
      key: "connected_number",
    },
    {
      title: "Message Limit",
      dataIndex: "message_limit",
      key: "message_limit",
    },
    {
      title: "Quality Rating",
      dataIndex: "quality_rating",
      key: "quality_rating",
    },
    {
      title: "Account Status",
      dataIndex: "account_status",
      key: "account_status",
    },
  ];
};


export const allCallScriptColumns = ({ params, setParams }) => {
  return [
    {
      title: "Name",
      dataIndex: "script",
      key: "script",
    },
  ];
};



export const EditActivityLeads = () => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "start_date",
      render: (text, record) => {
        return (
          <div>
            {text.map((v) => (
              <span
                style={{
                  color: v.color ?? "black",
                  padding: "3px 15px ",
                  backgroundColor: v.color
                    ? `${v.color}50`
                    : "rgba(0, 0, 0, 0.5)",
                  borderRadius: 5,
                  margin: 2,
                }}
              >
                {v.name}
              </span>
            ))}
          </div>
        );
      },
    },
  ];
}


export const EditActivityLeadsLogs = () => {
  return [
    {
      title: "Lead Name",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Phone",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sent Time",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Status (Color Coded)",
      dataIndex: "subject",
      key: "subject",
    },
  ];
}