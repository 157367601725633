import React, { useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import { Table, Input, Form } from "antd";

// Editable Cell component
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form form={form} component={false}>
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input onPressEnter={save} onBlur={save} />
        </Form.Item>
      </Form>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const CsvUploaderLeads = ({ dataSource, setDataSource }) => {
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columns = [
    { title: "Name", dataIndex: "name", editable: true },
    { title: "phone", dataIndex: "phone", editable: true },
    { title: "Email", dataIndex: "email", editable: true },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <h4 className="my-4">Upload CSV</h4>

      <Importer
        dataHandler={async (rows) => {
          const formattedRows = rows.map((row, index) => ({
            key: index,
            name: row["Name"],
            phone: row["phone"],
            email: row["Email"],
          }));
          setDataSource(formattedRows);
        }}
      >
        <ImporterField name="Name" label="Name" />
        <ImporterField name="phone" label="phone" />
        <ImporterField name="Email" label="Email" />
      </Importer>

      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        className="my-4"
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
      // rowClassName="editable-row"
      />
    </div>
  );
};

export default CsvUploaderLeads;
