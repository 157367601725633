import React from "react";
import {
  Layout,
  Menu,
  Button,
  Form,
  Input,
  Select,
  Typography,
  Card,
  Timeline,
} from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const CRMLeadDetails = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={250} theme="light">
        <div style={{ padding: "20px" }}>
          <Title level={4}>00:35:39</Title>
        </div>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">Lead Details</Menu.Item>
          <Menu.SubMenu key="sub1" title="Campaign Details">
            {/* Add campaign detail items here */}
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">Lead Details</Menu.Item>
            <Menu.Item key="2">Call Logs</Menu.Item>
            <Menu.Item key="3">Notes</Menu.Item>
          </Menu>
        </Header>
        <Content
          style={{ margin: "24px 16px", padding: 24, background: "#fff" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <Form layout="vertical">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Reference Number" required>
                        <Input placeholder="Please Enter Reference Number" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Lead Status">
                        <Select placeholder="Select Lead Status">
                          {/* Add options here */}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="First Name">
                        <Input placeholder="Fausto" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Last Name">
                        <Input placeholder="Reichert" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Company">
                        <Input placeholder="Frami Group" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Email">
                        <Input placeholder="waters.adelbert@example.com" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Website">
                        <Input placeholder="mccullough.info" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Phone No.">
                        <Input placeholder="+14804370560" />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item label="Notes">
                    <Input.TextArea
                      rows={4}
                      placeholder="I was thinking I should be raving mad--at least not so mad as it."
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="col-md-4">
                <Card title="Lead History">
                  <Timeline>
                    <Timeline.Item
                      dot={<FileTextOutlined style={{ fontSize: "16px" }} />}
                    >
                      A new notes added by Admin on 25-09-2024 10:09 am
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<UserOutlined style={{ fontSize: "16px" }} />}
                    >
                      A booking added by Admin for salesman Dave Wilkinson for
                      24-09-2024 09:30 pm
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                    >
                      Follow up added by Admin for staff member Admin for
                      24-09-2024 07:30 pm
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<PhoneOutlined style={{ fontSize: "16px" }} />}
                    >
                      A new call started by Admin on 25-09-2024 09:34 am
                    </Timeline.Item>
                  </Timeline>
                </Card>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CRMLeadDetails;
