
import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination, Button } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { allTeamsColumns } from "components/columns/columns"; // Adjust column config for teams
import { FetchAllTeams } from "store/action/index"; // Replace with Teams action
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AllTeams = ({ allTeamsData }) => {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ page: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllTeams({ setLoading: setLoading, params: params })); // Adjust fetch action for teams
  }, [params]);

  const HandleEdit = (record) => {
    navigate("/teams/add-team", { state: record });
  };

  const columns = allTeamsColumns({ params, setParams });

  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <Button onClick={() => HandleEdit(record)}>Edit</Button> // Correct: wrap HandleEdit in an anonymous function
    ),
  });

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };
  console.log(allTeamsData);

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Teams
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Link to={"/teams/add-team"}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Team
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <MainCard>
        <Table
          dataSource={allTeamsData ?? []}
          columns={columns}
          pagination={false} // Disable default pagination
          loading={loading}
        />

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allTeamsData?.meta?.current_page || 1}
            total={allTeamsData?.meta?.total || 0}
            pageSize={allTeamsData?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allTeamsData: state?.AllTeamsReducer?.allTeams, // Adjust the reducer for teams
  };
};

export default connect(mapStateToProps)(AllTeams);
