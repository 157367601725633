export const GET_ALL_MANAGEMENT = "GET_ALL_MANAGEMENT";
export const ALL_ASSIGN_PERMISSIONS = "ALL_ASSIGN_PERMISSIONS";
export const CREATE_ADD_USERS = "CREATE_ADD_USERS";
export const EDIT_UPDATE_USERS = "EDIT_UPDATE_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const FETCH_LOGIN_DATA = "FETCH_LOGIN_DATA";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_STATUS_USERS = "UPDATE_STATUS_USERS";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_ROLES = "VIEW_ROLES";
export const ASSIGN_PERMISSIONS = "ASSIGN_PERMISSIONS";
export const GETALL_PERMISSION = "GETALL_PERMISSION";
export const ROLE_PERMISSIONS = "ROLE_PERMISSIONS";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const All_COUNTS = "All_COUNTS";
export const ALL_COMPAIGNS = "ALL_COMPAIGNS";
export const CREATE_COMPAIGNS = "CREATE_COMPAIGNS";
export const ALL_LEADS = "ALL_LEADS";
export const ALL_LEADS_SELECT = "ALL_LEADS";
export const CREATE_LEADS = "CREATE_LEADS";
export const ALL_TAGS = "ALL_TAGS";
export const CREATE_TAGS = "CREATE_TAGS";
export const ALL_ACTIVITES = "ALL_ACTIVITES";
export const LEADS_DETAILS = "LEADS_DETAILS";
export const TAGS_EDIT = "TAGS_EDIT";
export const ALL_EMAIL_TEMPLATES = "ALL_EMAIL_TEMPLATES";
export const ADD_EMAIL_TEMPLATE = "ADD_EMAIL_TEMPLATE";
export const ALL_WHATSAPP_TEMPLATES = "ALL_WHATSAPP_TEMPLATES";
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const CREATE_WHATSAPP_TEMPLATE = "CREATE_WHATSAPP_TEMPLATE";
export const CREATE_EMAIL_SETTING = "CREATE_EMAIL_SETTING";
export const UPDATE_EMAIL_SETTING = "UPDATE_EMAIL_SETTING";
export const ALL_EMAIL_SETTING = "ALL_EMAIL_SETTING";
export const CREATE_WHATSAPP_SETTING = "CREATE_WHATSAPP_SETTING";
export const ADD_NEW_WHATSAPP_SETTING = "ADD_NEW_WHATSAPP_SETTING";
export const ALL_SMS_SETTING = "ALL_SMS_SETTING";
export const ALL_TEAMS = "ALL_TEAMS";
export const ADD_TEAMS = "ADD_TEAMS";
export const UPDATE_TEAMS = "UPDATE_TEAMS";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const ALL_CAMP_ACTIVITY = "ALL_CAMP_ACTIVITY";
export const ALL_CALL_SCRIPT = "ALL_CALL_SCRIPT";
export const CREATE_CALL_SCRIPT = "CREATE_CALL_SCRIPT";
export const ALL_CALL_LOGS = "ALL_CALL_LOGS";
export const ALL_SMS_LOGS = "ALL_SMS_LOGS";
export const ALL_EMAIL_LOGS = "ALL_EMAIL_LOGS";
export const ALL_WHATSAPP_LOGS = "ALL_WHATSAPP_LOGS";
export const ALL_SMS_TEMPLATES = "ALL_SMS_TEMPLATES";
export const ADD_LEADS_ACTIVITY = "ADD_LEADS_ACTIVITY";



















