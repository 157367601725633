import { ALL_LEADS_SELECT } from "../../constant/ActionType";

export default function AllLeadsSelectReducer(
  state = {
    allLeads: { data: [], links: {}, meta: {} },
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case ALL_LEADS_SELECT:
      return {
        ...state,
        allLeads: {
          ...(action.data
            ? {
                ...action?.data,
                data: [...state.allLeads?.data, ...action?.data?.data],
              }
            : state.allLeads),
        },
        statusCode: action.status,
      };

    default:
  }

  return state;
}
