import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Space,
  Form,
  Input,
  Steps,
  Select,
  DatePicker,
  Card,
} from "antd";
import { IoCloseOutline } from "react-icons/io5";
import CsvUploaderLeads from "components/inputs/CsvUploaderLeads";
import { useSelector } from "react-redux";
import CustomInput from "components/inputs/CustomInput";
import PaginatedSelect from "components/selects/SelectPagination";
import { dispatch } from "store/index";
import {
  CreateCampaign,
  FetchAllCompaigns,
  FetchAllLeads,
  FetchAllSelectLeads,
} from "store/action/index";

const DrawerRight = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const selector = useSelector((state) => state);
  const allActivitiesData = selector?.AllActivities?.allActivities?.data ?? [];
  const allMembersData = selector?.GetAllUsers?.getAllUsers?.users ?? [];
  const allLeadsData = selector?.AllLeadsSelectReducer?.allLeads?.data ?? [];

  // Add a default activity card when the component mounts
  useEffect(() => {
    form.setFieldsValue({
      activities: [{}],
    });
  }, [form]);

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (values) => {
    const allValues = { ...form.getFieldValue(), "csv-leads": dataSource };
    console.log(allValues)
    allValues.start_date = new Date(allValues?.start_date?.$d)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");
    allValues.end_date = allValues?.end_date?.$d ? new Date(allValues?.end_date?.$d)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-") : null;
    console.log(allValues?.end_date?.$d)
    dispatch(
      CreateCampaign({ setLoading: setIsLoading, fieldData: allValues, setOpen: setOpen, onClose })
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const next = async () => {
    try {
      await form.validateFields();
      setCurrentStep((prev) => prev + 1);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const callAllLeadsApi = (setLoading, params) => {
    dispatch(FetchAllSelectLeads({ setLoading: setLoading, params: params }));
  };

  const steps = [
    {
      title: "Basic Settings",
      description: "Campaign Settings",
      fields: (
        <>
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your Name!" }]}
              style={{ width: "50%" }}
            >
              <Input className="p-2" />
            </Form.Item>
            <Form.Item
              label="Members"
              name="members"
              rules={[{ required: true, message: "Please select a value" }]}
              style={{ width: "50%" }}
            >
              <Select placeholder="Select Members" mode="multiple">
                {allMembersData.map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4} placeholder="Enter email description" />
          </Form.Item>
          {/* <div>
            <Form.List name="activities">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    rowGap: 16,
                    flexDirection: "column",
                  }}
                >
                  {fields.map((field) => (
                    <Card
                      size="small"
                      title={`Activity ${field.name + 1}`}
                      key={field.key}
                      extra={
                        fields.length > 1 && (
                          <IoCloseOutline
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        )
                      }
                    >
                      <Form.Item
                        label="Type"
                        name={[field.name, "activity"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select an activity!",
                          },
                        ]}
                      >
                        <Select placeholder="Select Activity">
                          {allActivitiesData.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => {
                          return (
                            prevValues.activities !== currentValues.activities
                          );
                        }}
                      >
                        {({ getFieldValue }) => {
                          const selectedActivity = getFieldValue([
                            "activities",
                            field.name,
                            "activity",
                          ]);
                          if (selectedActivity === 2) {
                            return (
                              <Form.Item
                                label="Select Call Setting"
                                name={[field.name, "callSetting"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select call setting!",
                                  },
                                ]}
                              >
                                <Select placeholder="Select Call Setting">
                                  <Select.Option value="call_setting_1">
                                    Call Setting 1
                                  </Select.Option>
                                  <Select.Option value="call_setting_2">
                                    Call Setting 2
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            );
                          }

                          if (selectedActivity === 5) {
                            return (
                              <>
                                <Form.Item
                                  label="Select Email Provider"
                                  name={[field.name, "emailProvider"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select email provider!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Email Provider">
                                    <Select.Option value="email_provider_1">
                                      Email Provider 1
                                    </Select.Option>
                                    <Select.Option value="email_provider_2">
                                      Email Provider 2
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Select Email Template"
                                  name={[field.name, "emailTemplate"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select email template!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Email Template">
                                    <Select.Option value="template_1">
                                      Template 1
                                    </Select.Option>
                                    <Select.Option value="template_2">
                                      Template 2
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </>
                            );
                          }

                          if (selectedActivity === 4) {
                            return (
                              <>
                                <Form.Item
                                  label="Select SMS Provider"
                                  name={[field.name, "smsProvider"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select SMS provider!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select SMS Provider">
                                    <Select.Option value="sms_provider_1">
                                      SMS Provider 1
                                    </Select.Option>
                                    <Select.Option value="sms_provider_2">
                                      SMS Provider 2
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Select SMS Template"
                                  name={[field.name, "smsTemplate"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select SMS template!",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select SMS Template">
                                    <Select.Option value="template_1">
                                      Template 1
                                    </Select.Option>
                                    <Select.Option value="template_2">
                                      Template 2
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </>
                            );
                          }

                          return null;
                        }}
                      </Form.Item>
                    </Card>
                  ))}

                  <Button type="dashed" onClick={() => add()} block>
                    + Add Activity
                  </Button>
                </div>
              )}
            </Form.List>
          </div> */}
        </>
      ),
    },
    {
      title: "About Campaign",
      description: "Campaign Details",
      fields: (
        <>
          <>
            {/* <Form.Item
              label="Campaign Description"
              name="campaignDescription"
              rules={[
                {
                  required: true,
                  message: "Please input your campaign description!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item> */}

            <div className="d-flex gap-2">
              <Form.Item
                style={{ width: "100%" }}
                label="Start Date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: "Please select a start date!",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  className="flex-1"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                style={{ width: "100%" }}
                label="End Date"
                name="end_date"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("start_date") <= value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("End date must be after start date!")
                      );
                    },
                  }),
                ]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </>
        </>
      ),
    },
    {
      title: "Import Data",
      description: "Campaign Leads/Data",
      fields: (
        <>
          <div className="py-2">
            <Form.Item
              label="Leads"
              name="leads"
              rules={[{ required: true, message: "Please select a value" }]}
              style={{ width: "100%", marginBottom: "0px" }}
            >
              <PaginatedSelect
                optKey={"name"}
                optValue={"id"}
                opt={allLeadsData}
                callApi={callAllLeadsApi}
                allData={selector?.AllLeadsSelectReducer?.allLeads ?? {}}
              />
            </Form.Item>
          </div>
          <CsvUploaderLeads
            setDataSource={setDataSource}
            dataSource={dataSource}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }} onClick={showLargeDrawer}>
        {children}
      </div>
      <Drawer
        title="Add Campaign"
        zIndex={10000}
        width={1200}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div className="container-fluid mt-1">
          <div className="row">
            <Steps
              current={currentStep}
              items={steps.map((step) => ({
                title: step.title,
                description: step.description,
              }))}
              style={{ marginRight: "20px" }}
            />

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: "100%" }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="mt-3"
            >
              {steps[currentStep].fields}

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Space>
                  {currentStep > 0 && <Button onClick={prev}>Previous</Button>}
                  {currentStep < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      style={{ marginTop: "10px" }}
                    >
                      Next
                    </Button>

                  )}
                  {currentStep === steps.length - 1 && (
                    <Button
                      style={{ marginTop: "10px" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerRight;
