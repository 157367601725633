import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllWhatsappTemplates } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const WhatsappTemplate = ({ whatsappTemplates }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchAllWhatsappTemplates({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    console.log("whstaapptemplayedatat", whatsappTemplates);
  }, [whatsappTemplates]);

  const columns = [

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "content",
    },
    {
      title: "Footer",
      dataIndex: "footer",
      key: "content",
    },
  ];

  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      const editButton = true ? (
        <IconButton className="mx-2" type="primary" variant="contained">
          <EditOutlined />
        </IconButton>
      ) : null;

      return <span>{editButton}</span>;
    },
  });

  const HandleNavigate = () => {
    navigate("/templates/create-whatsapp-templates");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Whatsapp Templates
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          {true ? (
            <Button
              disableElevation
              className="px-2"
              size="large"
              onClick={HandleNavigate}
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#FF264C" size={26} />
              </span>{" "}
              Add Template
            </Button>
          ) : null}
        </Grid>
      </div>

      <MainCard>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={whatsappTemplates ?? []}
            columns={columns}
          />
        </Spin>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    whatsappTemplates: state.WhatsappTemplatesReducer.whatsappTemplates,
  };
};

export default connect(mapStateToProps)(WhatsappTemplate);
