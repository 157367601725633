import React from "react";
import MainCard from "components/MainCard";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { Modal, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useState } from "react";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import { dispatch } from "store/index";
import { AddEmailTemplate, EditEmailTemplate } from "store/action/index";
// import { CreateEmailTemplate, EditEmailTemplate } from "store/action/index";

const CreateEditEmailTemplate = ({ children, setLoading, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState(data?.body || ""); // Manage ReactQuill value

  const showModal = () => {
    setIsModalOpen(true);
    if (data) {
      form.setFieldsValue({
        templateName: data?.name,
        subject: data?.subject,
        body: data?.body,
        footer: data?.footer,
      });
      setEditorValue(data?.body); // Initialize editor with existing body
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          ...(data && { id: data?.id, _method: "PUT" }),
          name: values.templateName,
          subject: values.subject,
          body: editorValue, // Use editor value for body body
          footer: values.footer,
        };

        if (data) {
          dispatch(EditEmailTemplate({ payload, setLoading }));
        } else {
          console.log("template details", values);

          dispatch(AddEmailTemplate({ payload, setLoading }));
        }

        form.resetFields();
        setEditorValue(""); // Reset the editor
        handleCancel();
      })
      .catch(() => {
        toast.error("Please fill out all required fields", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  };

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        title={data ? "Update Email Template" : "Add Email Template"}
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            templateName: data?.name || "",
            subject: data?.subject || "",
            body: data?.body || "",
            footer: data?.footer || "",
          }}
        >
          <Form.Item
            label="Template Name"
            name="templateName"
            rules={[{ required: true, message: "Template Name is required" }]}
          >
            <Input placeholder="Enter template name" />
          </Form.Item>

          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: "Subject is required" }]}
          >
            <Input placeholder="Enter subject" />
          </Form.Item>

          <Form.Item
            label="Body"
            name="body"
            rules={[
              {
                required: true,
                validator: () => {
                  return editorValue == "<p> </p>" ||
                    editorValue === "<p><br></p>" ||
                    editorValue == ""
                    ? Promise.reject("Body is required")
                    : Promise.resolve();
                },
              },
            ]}
          >
            <TextRichEditor
              value={editorValue}
              onChange={setEditorValue}
              placeholder={"Enter email body"}
            />
          </Form.Item>
          {/* 
                    <Form.Item
                        label="Body"
                        name="body"
                        rules={[{ required: false, message: "" }]}
                    >
                        <Input.TextArea rows={8} style placeholder="Enter email body in HTML <html><head></head> <body></body></html>" />
                    </Form.Item> */}
          <Form.Item
            label="Footer"
            name="footer"
            rules={[{ required: false, message: "" }]}
          >
            <Input.TextArea rows={2} placeholder="Enter email footer body" />
          </Form.Item>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                // onClick={handleSubmit}
                disableElevation
                className="px-4"
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {data ? "Update" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(CreateEditEmailTemplate);
