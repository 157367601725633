import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import {
  can_add_users,
  can_edit_users,
  can_view_users,
} from "helper/permissionsCheck";

// Define icons
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

// Define Leads menu based on permissions
const leadsMenu =
  can_add_users || can_edit_users || can_view_users
    ? {
      id: "leads-group",
      title: "Leads",
      icon: icons.navigation,
      type: "group",
      children: [
        (can_add_users || can_edit_users || can_view_users) && {
          id: "all-leads",
          title: "All Leads",
          type: "item",
          url: "/leads/all-leads",
          icon: icons.allLeads,
          breadcrumbs: true,
        },
        // can_add_users && {
        //   id: "add-lead",
        //   title: "Add Lead",
        //   type: "item",
        //   url: "/leads/add-lead",
        //   icon: icons.addLead,
        //   breadcrumbs: true,
        // },
      ].filter(Boolean),
    }
    : null;

export default leadsMenu;
