import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllSMSTemplates } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { AllSmsTemplate } from "components/columns/columns";
import CreateEditSmsTemplate from "./Create&EditSmsTemplate";

const SmsTemplate = ({ allsmsTemplates }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(FetchAllSMSTemplates({ setLoading: setLoading }));
    }, [])

    useEffect(() => {
        console.log("allsmsTemplates", allsmsTemplates)
    }, [allsmsTemplates])

    const columns = AllSmsTemplate();
    // const columns = AllSmsTemplate({ params, setparams });



    //   const handlePageChange = (page) => {
    //     setparams({ ...params, page });
    //   };




    return (
        <>
            <Grid container alignItems="center" justifyContent="flex-end">
                <CreateEditSmsTemplate setLoading={setLoading}>
                    <Button disableElevation className="px-2 my-2" size="large" type="submit">
                        <span className="mx-2">
                            <IoMdAddCircle color="#FF264C" size={26} />
                        </span>{" "}
                        Add Sms Template
                    </Button>
                </CreateEditSmsTemplate>
            </Grid>
            <MainCard>
                <Table
                    // dataSource={allCampaignsData}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                />
                {/* <div style={{ textAlign: "center", marginTop: 16 }}>
                <Pagination
                    current={allcompaigns?.meta?.current_page || 1}
                    total={allcompaigns?.meta?.total || 0}
                    pageSize={allcompaigns?.meta?.per_page || 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div> */}
            </MainCard>
        </>
    )
}




const mapStateToProps = (state) => {
    return {
        allsmsTemplates: state.AllSmsTemplatesReducer.allsmsTemplates,

    };
};

export default connect(mapStateToProps)(SmsTemplate);