import React, { useEffect } from "react";
import { Form, Input, Select, Button, message, Row, Col } from "antd";
import MainCard from "components/MainCard";
import { useLocation, useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { FetchCreateNewWhatsappSetting } from "store/action/index";
const { TextArea } = Input;

const WhatsappSetting = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const isEditing = location.state ? true : false;
  const initialData = location.state ? location.state : null;

  useEffect(() => {
    if (isEditing && initialData) {
      form.setFieldsValue({
        display_name: initialData.display_name,
        connected_number: initialData.connected_number,
        whatsapp_business_id: initialData.whatsapp_business_id,
        quality_rating: initialData.quality_rating,
        message_limit: initialData.message_limit,
        account_status: initialData.account_status,
        number_status: initialData.number_status,
        business_address: initialData.business_address,
        business_email: initialData.business_email,
        business_description: initialData.business_description,
        business_industry: initialData.business_industry,
        meta_webhook_url: initialData.meta_webhook_url,
        meta_verify_token: initialData.meta_verify_token,
        api_key: initialData.api_key,
        api_secret: initialData.api_secret,
        phone_number: initialData.phone_number,
      });
    }
  }, [form, initialData, isEditing]);

  const onFinish = (values) => {
    if (isEditing) {
      message.success("WhatsApp setting updated successfully!");
      navigate("/settings/all-whatsapp-setting");
    } else {
      dispatch(FetchCreateNewWhatsappSetting({ fieldData: values }));
      message.success("WhatsApp setting created successfully!");
      navigate("/settings/all-whatsapp-setting");
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <MainCard>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="display_name"
              label="Display Name"
              rules={[
                { required: true, message: "Please enter the display name" },
                {
                  max: 255,
                  message: "Display name cannot exceed 255 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="connected_number"
              label="Connected Number"
              rules={[
                {
                  required: true,
                  message: "Please enter the connected number",
                },
                {
                  max: 20,
                  message: "Connected number cannot exceed 20 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="whatsapp_business_id"
              label="WhatsApp Business ID"
              rules={[
                {
                  required: true,
                  message: "Please enter the WhatsApp Business ID",
                },
                {
                  max: 255,
                  message: "WhatsApp Business ID cannot exceed 255 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="quality_rating"
              label="Quality Rating"
              rules={[
                {
                  required: true,
                  message: "Please enter the Quality Rating",
                },
                {
                  max: 50,
                  message: "Quality rating cannot exceed 50 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="message_limit"
              label="Message Limit"
              rules={[
                {
                  required: true,
                  message: "Please enter the Message Limit",
                },
                {
                  max: 50,
                  message: "Message limit cannot exceed 50 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="account_status"
              label="Account Status"
              rules={[
                {
                  required: true,
                  message: "Please enter the Account Status",
                },
                {
                  max: 50,
                  message: "Account status cannot exceed 50 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="number_status"
              label="Number Status"
              rules={[
                {
                  required: true,
                  message: "Please enter the Number Status",
                },
                {
                  max: 50,
                  message: "Number status cannot exceed 50 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="business_address"
              label="Business Address"
              rules={[
                {
                  required: true,
                  message: "Please enter the Busniness Address",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="business_email"
              label="Business Email"
              rules={[
                {
                  required: true,
                  message: "Please enter the Business Email",
                },
                { type: "email", message: "Please enter a valid email" },
                { max: 255, message: "Email cannot exceed 255 characters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="business_industry"
              label="Business Industry"
              rules={[
                {
                  required: true,
                  message: "Please enter the Business Industry",
                },
                {
                  max: 255,
                  message: "Business industry cannot exceed 255 characters",
                },
              ]}
            >
              <Select>
                <Select.Option value="entertainment">
                  Entertainment
                </Select.Option>
                <Select.Option value="retail">Retail</Select.Option>
                <Select.Option value="technology">Technology</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              {...formItemLayout}
              name="business_description"
              label="Business Description"
              rules={[
                {
                  required: true,
                  message: "Please enter the business description",
                },
                {
                  max: 1000,
                  message: "Business description cannot exceed 1000 characters",
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="meta_webhook_url"
              label="Meta Webhook URL"
              rules={[
                {
                  required: true,
                  message: "Please enter the Meta Webhook URL",
                },
                { type: "url", message: "Please enter a valid URL" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="meta_verify_token"
              label="Meta Verify Token"
              rules={[
                {
                  required: true,
                  message: "Please enter the Meta Verify Token",
                },
                {
                  max: 255,
                  message: "Meta verify token cannot exceed 255 characters",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="api_key"
              label="API Key"
              rules={[
                { required: true, message: "Please enter the API Key" },
                { max: 255, message: "API Key cannot exceed 255 characters" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="api_secret"
              label="API Secret"
              rules={[
                { required: true, message: "Please enter the API Secret" },
                {
                  max: 255,
                  message: "API Secret cannot exceed 255 characters",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              name="phone_number"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter the phone number" },
                {
                  pattern: /^\+?[1-9]\d{1,14}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {isEditing ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </MainCard>
  );
};

export default WhatsappSetting;
