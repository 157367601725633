import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Row, Col, Card, Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import { AddWhatsappTemplate } from "store/action/index";
const { TextArea } = Input;
const { TabPane } = Tabs;

const CreateWhstappTemplate = ({ EditActivityTemplate }) => {
  const [form] = Form.useForm();
  const [preview, setPreview] = useState({
    name: "",
    headerType: "text",
    headerText: "",
    headerImage: null,
    body: "",
    footer: "",
  });
  const [textCount, setTextCount] = useState(0);

  const calculateTextCount = (allValues) => {
    const { headerText = "", body = "", footer = "" } = allValues;
    return headerText.length + body.length + footer.length;
  };

  const onValuesChange = (changedValues, allValues) => {
    setPreview((prev) => ({
      ...prev,
      ...allValues,
      headerImage: allValues.headerImage || prev.headerImage,
    }));
    setTextCount(calculateTextCount(allValues));
  };

  const onFinish = (values) => {
    dispatch(AddWhatsappTemplate({ values }));
  };

  const handleHeaderTypeChange = (key) => {
    setPreview((prev) => ({
      ...prev,
      headerType: key,
      headerText: key === "text" ? prev.headerText : "",
    }));
  };

  const handleHeaderTextChange = (e) => {
    const newValue = e.target.value;
    setPreview((prev) => ({
      ...prev,
      headerText: newValue,
    }));
    setTextCount(calculateTextCount({ ...preview, headerText: newValue }));
  };

  useEffect(() => {
    if (EditActivityTemplate && Object.keys(EditActivityTemplate).length > 0) {
      console.log("EditActivityTemplate has data", EditActivityTemplate);

      form.setFieldsValue({
        name: EditActivityTemplate.name || "",
        headerType: EditActivityTemplate.header?.type || "text",
        headerText: EditActivityTemplate.header?.content || "",
        body: EditActivityTemplate.body || "",
        footer: EditActivityTemplate.footer || "",
      });

      setPreview({
        name: EditActivityTemplate.name || "",
        headerType: EditActivityTemplate.header?.type || "text",
        headerText: EditActivityTemplate.header?.content || "",
        headerImage: null,
        body: EditActivityTemplate.body || "",
        footer: EditActivityTemplate.footer || "",
      });
      setTextCount(
        calculateTextCount({
          headerText: EditActivityTemplate.header?.content || "",
          body: EditActivityTemplate.body || "",
          footer: EditActivityTemplate.footer || "",
        })
      );
    }
  }, [EditActivityTemplate, form]);

  return (
    <MainCard>
      <Row gutter={24}>
        <Col span={12}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={onValuesChange}
            className='mt-3'
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter a name" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="headerType" label="Header (Optional)">
              <Tabs onChange={handleHeaderTypeChange}>
                <TabPane tab="Text" key="text">
                  <Form.Item name="headerText" noStyle>
                    <Input
                      placeholder="Add a title for this header"
                      value={preview.headerText}
                      onChange={handleHeaderTextChange}
                    />
                  </Form.Item>
                </TabPane>
                <TabPane tab="Image" key="image">
                  <Form.Item name="headerImage" noStyle>
                    <Upload
                      accept="image/*"
                      beforeUpload={() => false}
                      maxCount={1}
                      listType="picture-card"
                      onChange={({ fileList }) => {
                        const file = fileList[0]?.originFileObj || null;
                        form.setFieldsValue({ headerImage: file });
                        setPreview((prev) => ({
                          ...prev,
                          headerImage: file,
                        }));
                      }}
                    >
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  </Form.Item>
                </TabPane>
              </Tabs>
            </Form.Item>

            <Form.Item
              name="body"
              label="Body (Required)"
              rules={[
                { required: true, message: "Please enter the body content" },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter the text for your message."
              />
            </Form.Item>

            <Form.Item name="footer" label="Footer Description (Optional)">
              <Input placeholder="Add a short line of text to the bottom of your message template" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Template
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          <p>Total Character Count: {textCount}</p>

          <Card
            title="Template Preview"
            style={{
              width: "100%",
              height: "100%",
              backgroundImage:
                'url("https://cdn.wallpapersafari.com/68/67/U1SFl7.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >

            <div
              style={{
                padding: "14px",
                borderRadius: "8px",
                minHeight: "300px",
                paddingTop: "0px",
              }}
            >
              {preview?.headerType !== "image" && preview?.headerText && (
                <h3>{preview.headerText}</h3>
              )}
              {preview?.headerType === "image" && preview?.headerImage && (
                <div style={{ marginBottom: "10px" }}>
                  <img
                    src={URL.createObjectURL(preview?.headerImage)}
                    alt="Header"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                </div>
              )}
              {preview?.body && <p>{preview?.body}</p>}
              {preview?.footer && (
                <p style={{ fontSize: "0.8em", color: "#666" }}>
                  {preview?.footer}
                </p>
              )}
            </div>
          </Card>

        </Col>
      </Row>
    </MainCard>
  );
};

export default CreateWhstappTemplate;
