import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { Pagination } from "antd";
import { dispatch } from "store/index";
import { compaignsColumns } from "components/columns/columns";
import { FetchAllCompaigns } from "store/action/index";
import { textAlign } from "../../../node_modules/@mui/system/index";
import DrawerRight from "../../components/drawer/AddCampaignDrawer";
import CampaignCard from "components/cards/statistics/CallManagerCard";

const campaigns = [
    {
        id: 1,
        title: 'Live Event Campaign 1',
        members: 3,
        progress: 9,
        remainingLeads: '4/44',
        lastActioner: 'Admin',
        startedOn: '10-09-2024 06:32 pm'
    },
    {
        id: 2,
        title: 'Webinar Campaign',
        members: 2,
        progress: 50,
        remainingLeads: '22/44',
        lastActioner: 'User1',
        startedOn: '12-09-2024 08:00 am'
    },

    {
        id: 2,
        title: 'Webinar Campaign',
        members: 2,
        progress: 50,
        remainingLeads: '22/44',
        lastActioner: 'User1',
        startedOn: '12-09-2024 08:00 am'
    },
    {
        id: 2,
        title: 'Webinar Campaign',
        members: 2,
        progress: 50,
        remainingLeads: '22/44',
        lastActioner: 'User1',
        startedOn: '12-09-2024 08:00 am'
    },
    {
        id: 2,
        title: 'Webinar Campaign',
        members: 2,
        progress: 50,
        remainingLeads: '22/44',
        lastActioner: 'User1',
        startedOn: '12-09-2024 08:00 am'
    },

    // Add more campaign objects here
];


const AllCampaigns = ({ allcompaigns }) => {
    const [loading, setLoading] = useState(true);
    const [params, setparams] = useState({ page: 1, activities_id: 2 });

    useEffect(() => {
        setLoading(true);
        dispatch(FetchAllCompaigns({ setLoading: setLoading, params: params }));
    }, [params]);

    const columns = compaignsColumns({ params, setparams });

    const handlePageChange = (page) => {
        setparams({ ...params, page });
    };

    return (
        <>
            <div className="mb-4">
                <MainCard>
                    <div style={{ display: "flex" }}>
                        <Grid container alignItems="center" justifyContent="flex-start">
                            <Grid item>
                                <span
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Call Manager
                                </span>
                            </Grid>
                        </Grid>

                    </div>
                </MainCard>
            </div>

            <>

                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: '20px' }}>
                    {Array.isArray(allcompaigns) ? allcompaigns.map((campaign) => (
                        <CampaignCard key={campaign.id} campaign={campaign} />
                    )) : <p>No Data</p>}
                </div>
                <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={allcompaigns?.meta?.current_page || 1}
                        total={allcompaigns?.meta?.total || 0}
                        pageSize={allcompaigns?.meta?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div>
            </>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        allcompaigns: state?.AllCompainReducer?.allcompaigns?.data,
    };
};

export default connect(mapStateToProps)(AllCampaigns);
